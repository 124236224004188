import {LitElement, html} from 'lit'


customElements.define('c-waiting', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
  }

  render(){    
    return html`
    <style>      
      * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .outer-box { text-align: center; }
      .mi-text { margin: 30px auto;  width: 100%; font-size: 1.5em; 
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
      .spinner{
          border-top: 4px solid var(--brand-light);
          border-right: 4px solid transparent;
          border-radius: 50%;
          animation: rotation .8s linear infinite;
          margin: 20px auto;
          height: 70px; 
          width: 70px;
        }
        @keyframes rotation{
          from{ transform: rotate(0deg); }
          to{ transform: rotate(360deg); }
        }

        @media all and (max-width: 450px){ 
          .mi-text {  font-size: 1.2em; }
        }

        @media all and (min-width: 769px){  
        }
      </style>

      <div class="outer-box">
        <p class="mi-text"> Sending message ...</p>
        <br><br>
        <div class="spinner"></div>
      </div>

    `
  }
})
