import App from './App'

class MailAPI {
  async sendContact(formData){
    if(!formData){
      const errorMessage = 'Empty Request'
        return errorMessage
    }
    const response = await fetch(`${App.apiBase}/mail`, {
      method: "POST",    
      headers: {"Content-Type" : "application/json"},
      body: formData
    })
    if(!response.ok){
      const err = await response.json()
      if(err) console.log(err)
      throw new Error('Problem also')
    }
    const okMessage = await response.json()
    return okMessage
  }
}

export default new MailAPI()